//
// Map
//

.map-wrapper {
  position: relative;
}

.map-container {
  height: $map-height-sm;

  > iframe {
    border: none;
  }
}

//
// Media query breakpoint
//

@include media-breakpoint-up(md) {

  .map-container {
    height: $map-height-md;
  }

}

@include media-breakpoint-up(lg) {

  .map-wrapper {
    height: $map-height-lg;
  }

  .map-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .map-info-box {
    position: absolute;
    top: 50%;
    width: 100%;
    @include transform-prefix("translateY", "(-50%)");
  }

}
