//
// Info box style 1
//

%info-box-custom-card {
  display: block;
  overflow: hidden;
}

.info-box-1 {
  @extend %info-box-custom-card;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: $info-box-1-overlay-bg;
    opacity: $info-box-1-overlay-opacity;

    @include transition-prefix("all", $info-box-hover-duration, $transition-timing-base);
  }

  .info-box-btn {
    @include transition-prefix("all", $info-box-hover-duration, $transition-timing-base);
  }

  .info-box-btn-inner {
    visibility: hidden;
    opacity: 0;

    @include transform-prefix("translateY", "(30%)");
    @include transition-prefix("all", $info-box-hover-duration, $transition-timing-base);
    transition-delay: 0;
  }

  &:hover {

    &::before {
      opacity: ( $info-box-1-overlay-opacity + .25 );
    }

    .info-box-btn {
      /* stylelint-disable-next-line declaration-no-important */
      margin-bottom: 0 !important;
    }

    .info-box-btn-inner {
      visibility: visible;
      opacity: 1;

      @include transform-prefix("translateY", "(0)");
      @include transition-prefix("all", ( $info-box-hover-duration + .1 ), $transition-timing-base);
      transition-delay: $info-box-btn-delay;
    }
  }
}

//
// Info box style 2
//

.info-box-2 {
  @extend %info-box-custom-card;

  .card-img-overlay {
    z-index: 1;
    padding: 0;
  }

  .info-box-details {
    padding: $info-box-2-caption-padding-y $info-box-2-caption-padding-x;
    background-color: $info-box-2-caption-bg;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: $info-box-2-overlay-bg;
    opacity: 0;

    @include transition-prefix("all", $info-box-hover-duration, $transition-timing-base);
  }

  .info-box-btn,
  > img {
    @include transition-prefix("all", $info-box-hover-duration, $transition-timing-base);
  }

  .info-box-btn-inner {
    visibility: hidden;
    opacity: 0;

    @include transition-prefix("all", $info-box-hover-duration, $transition-timing-base);
    transition-delay: 0;
  }

  &:hover {

    &::after {
      opacity: 1;
    }

    .info-box-btn {
      /* stylelint-disable-next-line declaration-no-important */
      margin-bottom: 0 !important;
    }

    .info-box-btn-inner {
      visibility: visible;
      opacity: 1;

      @include transition-prefix("all", ( $info-box-hover-duration + .1 ), $transition-timing-base);
      transition-delay: $info-box-btn-delay;
    }

    > img {
      @include transform-prefix("translateY", "(-10%)");
    }
  }
}

//
// Info box style 3
//

%info-box-3-transition-out {
  @include transition-prefix("all", $info-box-hover-duration, $transition-timing-base);
}

.info-box-3 {
  @extend %info-box-custom-card;

  .hover-transition {
    @extend %info-box-3-transition-out;
  }

  .card-img-overlay {
    z-index: 3;
  }

  .info-box-details {
    position: relative;

    &::after {
      position: absolute;
      top: -30px;
      right: -30px;
      bottom: -30px;
      left: -30px;
      z-index: -1;
      content: "";

      @include gradient-y($gradient-bg-element-color-start, $gradient-bg-element-color-end);
      @include transition-prefix("opacity", $transition-duration-base, $transition-timing-base);
    }
  }

  .info-box-title {
    > span {
      display: block;
      font-size: $info-box-3-subtitle-size;
    }
  }

  .info-box-desc {
    @extend %info-box-3-transition-out;
  }

  .card-text {
    opacity: 0;
    @include transition-prefix("all", $transition-duration-base, $transition-timing-base);
    @include transform-prefix("translateX", "(15%)");
  }

  .info-box-btn-inner {
    opacity: 0;
    @extend %info-box-3-transition-out;
  }

  &:hover {

    .info-box-details {
      &::after {
        opacity: 0;
      }
    }

    .info-box-desc {
      /* stylelint-disable-next-line declaration-no-important */
      margin-bottom: 0 !important;
    }

    .card-text {
      opacity: 1;
      @include transform-prefix("translateX", "(0)");
      @include transition-prefix("all", ( $info-box-hover-duration + .1 ), $transition-timing-base);
      transition-delay: ( $info-box-btn-delay + .15 );
    }

    .info-box-btn-inner {
      opacity: 1;
      @include transition-prefix("all", $info-box-hover-duration, $transition-timing-base);
      transition-delay: ( $info-box-btn-delay + .2 );
    }
  }
}

//
// Info box style 4
//

%info-box-4-transition-out {
  @include transition-prefix("all", $transition-duration-base, $transition-timing-base);
}

.info-box-4 {
  @extend %info-box-custom-card;

  .info-box-caption {
    padding: $info-box-4-caption-py 0;
  }

  .card-title {
    opacity: 0;
    @extend %info-box-4-transition-out;
    @include transform-prefix("translateY", "(-50%)");
  }

  .card-text {
    opacity: 0;
    @extend %info-box-4-transition-out;
    @include transform-prefix("translateX", "(15%)");
  }

  .info-box-btn {
    position: absolute;
    bottom: $info-box-padding;
    left: 0;
    width: 100%;
    padding: 0 $info-box-padding;
    opacity: 0;

    @extend %info-box-4-transition-out;
  }

  &:hover {

    .card-title {
      opacity: 1;
      @include transform-prefix("translateY", "(0)");
      @include transition-prefix("all", $info-box-hover-duration, $transition-timing-base);
      transition-delay: $info-box-btn-delay;
    }

    .card-text {
      opacity: 1;
      @include transform-prefix("translateX", "(0)");
      @include transition-prefix("all", $info-box-hover-duration, $transition-timing-base);
      transition-delay: ( $info-box-btn-delay + .05 );
    }

    .info-box-btn {
      opacity: 1;
      @include transition-prefix("all", $info-box-hover-duration, $transition-timing-base);
      transition-delay: $info-box-btn-delay;
    }
  }
}
