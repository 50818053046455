//
// Input groups
//

@mixin input-group-focus() {
  border-color: $input-focus-border-color;

  @if $enable-shadows {
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
  } @else {
    box-shadow: $input-focus-box-shadow;
  }
}

@mixin input-group-focus-light-1() {
  border-color: $input-focus-border-color;
  box-shadow: $input-focus-box-shadow;
}

@mixin input-group-focus-light-2() {
  border-color: $white;
  box-shadow: $input-focus-box-shadow-light;
}
