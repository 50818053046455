//
// Form
//

@mixin form-control-focus-light-1() {
  &:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }
}

@mixin form-control-focus-light-2() {
  &:focus {
    border-color: $white;
    box-shadow: $input-focus-box-shadow-light;
  }
}

@mixin form-validation-state($state, $color) {

  .#{$state}-tooltip {
    left: 0;
    font-size: 80%;
  }

  .form-control,
  .custom-select {
    .was-validated &:#{$state},
    &.is-#{$state} {
      border-color: $color;

      &:focus {
        border-color: $color;
        box-shadow: $input-focus-box-shadow;

        ~ .input-focus-bg {
          /* stylelint-disable-next-line declaration-no-important */
          border-color: $color !important;
        }
      }

      ~ .input-focus-bg {
        border-color: $color;
      }
    }
  }

  .custom-select {

    .was-validated &:#{$state},
    &.is-#{$state} {

      @if $enable-validation-icons {
        $form-feedback-icon: if($state == "valid", $form-feedback-icon-valid, $form-feedback-icon-invalid);
        background: $custom-select-indicator no-repeat right ( $custom-select-padding-x - .1875 ) center / $custom-select-bg-size, $form-feedback-icon no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;
      }
    }

    .was-validated.form-light-1 &:#{$state},
    &.is-#{$state} {

      @if $enable-validation-icons {
        $form-feedback-icon: if($state == "valid", $form-feedback-icon-valid, $form-feedback-icon-invalid);
        background: $custom-select-indicator-gray no-repeat right ( $custom-select-padding-x - .1875 ) center / $custom-select-bg-size, $form-feedback-icon no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;
      }
    }

    .was-validated.form-light-2 &:#{$state},
    &.is-#{$state} {

      @if $enable-validation-icons {
        $form-feedback-icon: if($state == "valid", $form-feedback-icon-valid, $form-feedback-icon-invalid);
        background: $custom-select-indicator-white no-repeat right ( $custom-select-padding-x - .1875 ) center / $custom-select-bg-size, $form-feedback-icon no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;
      }
    }
  }

  .custom-control-input {
    .was-validated &:#{$state},
    &.is-#{$state} {

      &:focus {
        ~ .custom-control-label::before {
          box-shadow: $custom-control-indicator-focus-box-shadow;
        }
      }
    }
  }

  .custom-file-input {
    .was-validated &:#{$state},
    &.is-#{$state} {

      &:focus {
        ~ .custom-file-label {
          box-shadow: $input-focus-box-shadow;
        }
      }
    }
  }

  .form-light-2.was-validated {
    .form-control,
    .custom-select {
      &:focus {
        box-shadow: $input-focus-box-shadow-light;
      }
    }

    .custom-file-input {
      &:focus {
        ~ .custom-file-label {
          box-shadow: $input-focus-box-shadow-light;
        }
      }
    }
  }
}
