//
// Lightbox video
//

.lightbox-video {
  overflow: hidden;

  .lightbox-video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: $lightbox-video-overlay-opacity;
    @include transition-prefix("opacity", $transition-duration-base, $transition-timing-base);
  }

  .play-btn {
    @include hover() {
      + .lightbox-video-overlay {
        opacity: $lightbox-video-overlay-opacity + .15;
      }
    }
  }
}
