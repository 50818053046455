//
// Testimonial
//

%testimonial-name {
  font-size: $testimonial-name-font-size;
  font-weight: $testimonial-name-font-weight;
  color: $testimonial-name-color;
}

.testimonial-1 {

  .testimonial-image {
    margin-bottom: $testimonial-image-mb;

    figure {
      width: $testimonial-image-w;
      height: $testimonial-image-w;
      margin: 0 auto;

      img {
        border-radius: $circle-border-radius;

        @if $enable-shadows-theming {
          box-shadow: $testimonial-image-shadow;
        } @else {
          box-shadow: none;
        }
      }
    }
  }

  .testimonial-details {
    position: relative;
    padding: $testimonial-box-padding;
    background-color: $testimonial-1-bg;

    &::before {
      position: absolute;
      top: -$testimonial-quote-h;
      left: 0;
      width: 0;
      height: 0;
      content: "";
      border-bottom: $testimonial-quote-h solid $testimonial-1-quote-bg;
      border-left: ( $testimonial-quote-h + 5 ) solid transparent;
    }
  }

  .tesimonial-name {
    @extend %testimonial-name;
  }
}

//
// Testimonial 2
//

.testimonial-2 {

  .testimonial-image {
    margin-bottom: $testimonial-image-mb;

    figure {
      width: $testimonial-image-w;
      height: $testimonial-image-w;
      margin: 0 auto;

      img {
        border-radius: $circle-border-radius;

        @if $enable-shadows-theming {
          box-shadow: $testimonial-image-shadow;
        } @else {
          box-shadow: none;
        }
      }
    }
  }

  .testimonial-details {
    position: relative;
    padding: $testimonial-box-padding;
    box-shadow: $testimonial-2-shadow;
    @include gradient-y($testimonial-2-bg, rgba($testimonial-2-bg, 0));

    @if $enable-rounded-theming {
      border-radius: $testimonial-2-rounded;
    } @else {
      border-radius: 0;
    }

    &::before {
      position: absolute;
      top: -$testimonial-quote-h;
      left: 50%;
      width: 0;
      height: 0;
      content: "";
      border-color: transparent transparent $testimonial-2-bg transparent;
      border-style: solid;
      border-width: $testimonial-2-quote-border-w;

      @include transform-prefix("translateX", "(-50%)");
    }
  }

  .tesimonial-name {
    @extend %testimonial-name;
  }
}

//
// Testimonial 3
//

.testimonial-3 {
  padding: $testimonial-3-py 0;
  text-align: center;

  .testimonial-quote {
    margin-bottom: $testimonial-3-quote-mb;
    font-size: $testimonial-3-quote-font-size;
    font-style: italic;
    font-weight: $testimonial-3-quote-font-weight;
  }

  .testimonial-image {
    margin-bottom: $testimonial-image-mb;

    figure {
      width: $testimonial-image-w;
      height: $testimonial-image-w;
      margin: 0 auto;

      img {
        border-radius: $circle-border-radius;

        @if $enable-shadows-theming {
          box-shadow: $testimonial-image-shadow;
        } @else {
          box-shadow: none;
        }
      }
    }
  }

  .tesimonial-name {
    @extend %testimonial-name;

    span {
      display: block;
      font-size: 70%;
      font-style: italic;
      font-weight: $testimonial-name-wording-font-weight;
      color: $testimonial-name-wording-color;
    }
  }

  .testimonial-quote-icon {
    margin-bottom: $testimonial-quote-icon-mb;
    color: $testimonial-quote-icon-color;
  }
}

// Testimonial light 1
//
// Use for container background dark

.testimonial-light-1 {

  &.testimonial-1 {

    .testimonial-details {
      background-color: $testimonial-1-light-1-bg;

      &::before {
        border-bottom: $testimonial-quote-h solid $testimonial-1-light-1-quote-bg;
      }
    }
  }

  &.testimonial-2 {
    .testimonial-details {
      box-shadow: $testimonial-2-light-1-shadow;
      @include gradient-y($testimonial-2-light-1-bg, rgba($testimonial-2-light-1-bg, 0));

      &::before {
        border-color: transparent transparent $testimonial-2-light-1-bg transparent;
      }
    }
  }
}

// Testimonial light 2
//
// Use for container background color

.testimonial-light-2 {

  &.testimonial-1 {

    .testimonial-details {
      background-color: $testimonial-1-light-2-bg;

      &::before {
        border-bottom: $testimonial-quote-h solid $testimonial-1-light-2-quote-bg;
      }
    }
  }
}

//
// Media query breakpoint
//

@include media-breakpoint-up(md) {

  .testimonial-1 {
    display: table;
    width: 100%;

    .testimonial-image {
      display: table-cell;
      width: ( $testimonial-image-w * 1.5 );
      padding: 0 ( $testimonial-image-w * .5 ) 0 0;
      vertical-align: top;
    }

    .testimonial-details {
      &::before {
        top: 0;
        left: -$testimonial-quote-h;
        display: table-cell;
        vertical-align: top;
        border-bottom: ( $testimonial-quote-h + 5 ) solid $testimonial-1-quote-bg;
        border-left: $testimonial-quote-h solid transparent;
      }
    }

    &.testimonial-light-1 {
      .testimonial-details {
        &::before {
          border-bottom: ( $testimonial-quote-h + 5 ) solid $testimonial-1-light-1-quote-bg;
        }
      }
    }

    &.testimonial-light-2 {
      .testimonial-details {
        &::before {
          border-bottom: ( $testimonial-quote-h + 5 ) solid $testimonial-1-light-2-quote-bg;
        }
      }
    }
  }

  .testimonial-2 {
    display: table;
    width: 100%;

    .testimonial-image {
      display: table-cell;
      width: ( $testimonial-image-w * 1.5 );
      padding: 0 ( $testimonial-image-w * .5 ) 0 0;
      vertical-align: top;
    }

    .testimonial-details {
      box-shadow: $testimonial-2-quote-l-shadow;
      @include gradient-x($testimonial-2-bg, rgba($testimonial-2-bg, 0));

      &::before {
        top: ( $testimonial-quote-h + 10 );
        left: -$testimonial-quote-h;
        display: table-cell;
        vertical-align: top;
        border-color: transparent $testimonial-2-bg transparent transparent;
        border-style: solid;
        border-width: $testimonial-2-quote-left-border-w;

        @include transform-prefix("translateX", "(0)");
      }
    }

    &.testimonial-light-1 {

      .testimonial-details {
        box-shadow: $testimonial-2-light-1-quote-l-shadow;
        @include gradient-x($testimonial-2-light-1-bg, rgba($testimonial-2-light-1-bg, 0));

        &::before {
          border-color: transparent $testimonial-2-light-1-bg transparent transparent;
        }
      }
    }
  }
}
