//
// Body
//

body {
  overflow-x: hidden;
  font-size: $font-size-base * .875;
}

//
// Typography
//

dt {
  color: $heading-color-base;
}

//
// Links
//

a {
  @include transition-prefix("color", $transition-duration-base, $transition-timing-base);

  @include hover {
    text-decoration: $link-decoration;
  }
}

//
// Forms
//

label {
  font-size: $label-font-size;
}
