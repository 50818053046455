//
// Headings
//

h1,
.h1 { font-size: $h1-font-size-sm; }
h2,
.h2 { font-size: $h2-font-size-sm; }
h3,
.h3 { font-size: $h3-font-size-sm; }
h4,
.h4 { font-size: $h4-font-size-sm; }
h5,
.h5 { font-size: $h5-font-size-sm; }
h6,
.h6 { font-size: $h6-font-size-sm; }

@include media-breakpoint-up(md) {
  h1,
  .h1 { font-size: $h1-font-size; }
  h2,
  .h2 { font-size: $h2-font-size; }
  h3,
  .h3 { font-size: $h3-font-size; }
  h4,
  .h4 { font-size: $h4-font-size; }
  h5,
  .h5 { font-size: $h5-font-size; }
  h6,
  .h6 { font-size: $h6-font-size; }
}

// Lead
.lead-sm {
  font-size: $lead-sm-font-size;
}

// Type display classes
.display-1 {
  font-size: $display1-size-xs;
}
.display-2 {
  font-size: $display2-size-xs;
}
.display-3 {
  font-size: $display3-size-xs;
}
.display-4 {
  font-size: $display4-size-xs;
}
.display-ultra-1 {
  font-size: $display1-ultra-size-xs;
}
.display-ultra-2 {
  font-size: $display2-ultra-size-xs;
}
.display-ultra-3 {
  font-size: $display3-ultra-size-xs;
}
.display-ultra-4 {
  font-size: $display4-ultra-size-xs;
}
@include media-breakpoint-up(md) {
  .display-1 {
    font-size: $display1-size-md;
  }
  .display-2 {
    font-size: $display2-size-md;
  }
  .display-3 {
    font-size: $display3-size-md;
  }
  .display-4 {
    font-size: $display4-size-md;
  }
  .display-ultra-1 {
    font-size: $display1-ultra-size-md;
  }
  .display-ultra-2 {
    font-size: $display2-ultra-size-md;
  }
  .display-ultra-3 {
    font-size: $display3-ultra-size-md;
  }
  .display-ultra-4 {
    font-size: $display4-ultra-size-md;
  }
}
@include media-breakpoint-up(xl) {
  .display-1 {
    font-size: $display1-size-xl;
  }
  .display-2 {
    font-size: $display2-size-xl;
  }
  .display-3 {
    font-size: $display3-size-xl;
  }
  .display-4 {
    font-size: $display4-size-xl;
  }
  .display-ultra-1 {
    font-size: $display1-ultra-size-xl;
  }
  .display-ultra-2 {
    font-size: $display2-ultra-size-xl;
  }
  .display-ultra-3 {
    font-size: $display3-ultra-size-xl;
  }
  .display-ultra-4 {
    font-size: $display4-ultra-size-xl;
  }
}

//
// Blockquotes
//

.blockquote {
  margin-bottom: ($spacer * 2);
  font-size: $blockquote-font-size;
  font-style: italic;
  font-weight: $font-weight-500;
  line-height: $blockquote-line-height;
  color: $blockquote-color;
}
.blockquote-footer {
  margin-top: $blockquote-footer-mt;
  font-size: $blockquote-footer-font-size;
  font-style: normal;
  font-weight: $font-weight-base;

  &::before {
    position: relative;
    top: $blockquote-footer-dash-top;
    display: inline-block;
    width: $blockquote-footer-dash-width;
    height: $blockquote-footer-dash-height;
    margin-right: $blockquote-footer-dash-mr;
    content: "";
    background-color: $blockquote-footer-dash-bg-color;
  }
}
.blockquote-light-1 {
  color: $white;

  .blockquote-footer {
    color: $white-50;

    &::before {
      background-color: $white-50;
    }
  }
}
.blockquote-light-2 {
  color: $white;

  .blockquote-footer {
    color: $white-75;

    &::before {
      background-color: $white-75;
    }
  }

  &.blockquote-3 {
    /* stylelint-disable-next-line declaration-no-important */
    border-color: $white !important;
  }
}
.blockquote-2 {
  @extend .blockquote;
  padding: $blockquote-2-padding;
  /* stylelint-disable declaration-no-important */
  border-color: $blockquote-2-border-color !important;
  border-width: $blockquote-border-width !important;

  &.blockquote-light-1 {
    border-color: $blockquote-2-light-1-border-color !important;
  }

  &.blockquote-light-2 {
    border-color: $blockquote-2-light-2-border-color !important;
  }
  /* stylelint-enable */
}
.blockquote-3 {
  @extend .blockquote;
  padding: $blockquote-3-padding;
  background-color: $white;
  /* stylelint-disable */
  border-color: $main-color !important;
  border-width: $blockquote-border-width !important;
  /* stylelint-enable */

  &.blockquote-light-1,
  &.blockquote-light-2 {
    background-color: $blockquote-3-light-bg-color;

    .blockquote-footer {
      color: $white-50;

      &::before {
        background-color: $white-50;
      }
    }
  }
}
