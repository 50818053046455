//
// Breadcrumb
//

.breadcrumb-item {
  font-size: $breadcrumb-font-size;
  font-weight: $font-weight-500;

  a {
    color: $breadcrumb-color;
    text-decoration: none;

    @include transition-prefix("all", $transition-duration-base, $transition-timing-base);

    &:hover {
      color: $main-color;
    }
  }

  &.active {
    color: $breadcrumb-color;
  }

  + .breadcrumb-item {
    padding-left: $breadcrumb-item-padding;

    &::before {
      padding-right: $breadcrumb-divider-padding;
      padding-left: $breadcrumb-divider-padding;
      margin-right: $breadcrumb-item-padding;
      color: $breadcrumb-divider-color;
    }
  }
}

// Breadcrumb light 1
//
// Use for container background dark
%breadcrumb-color-light-1 {
  color: $breadcrumb-color-light-1;
}

.breadcrumb-light-1 {

  .breadcrumb-item {

    &.active,
    a {
      @extend %breadcrumb-color-light-1;
    }

    + .breadcrumb-item::before {
      @extend %breadcrumb-color-light-1;
    }

    a:hover {
      color: $main-color;
    }
  }
}

// Breadcrumb light 2
//
// Use for container background color
%breadcrumb-color-light-2 {
  color: $breadcrumb-color-light-2;
}

.breadcrumb-light-2 {

  .breadcrumb-item {

    &.active,
    a {
      @extend %breadcrumb-color-light-2;
    }

    + .breadcrumb-item::before {
      @extend %breadcrumb-color-light-2;
    }

    a:hover {
      /* stylelint-disable-next-line declaration-no-important */
      color: $breadcrumb-color-light-2 !important;
      opacity: $hover-fade-opacity;
    }
  }
}
