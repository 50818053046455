//
// Logo
//

.navik-header {
  .logo {
    img {
      width: $logo-width;
    }
  }
}
