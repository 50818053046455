.carousel-inner {
  @if $enable-rounded-theming {
    border-radius: $border-radius-lg;
  } @else {
    border-radius: 0;
  }
}

//
// Left/right controls for nav
//

.carousel-control-prev,
.carousel-control-next {
  @include transition-prefix("opacity", $transition-duration-base, $transition-timing-base);

  @include hover-focus {
    /* stylelint-disable-next-line declaration-no-important */
    opacity: $carousel-control-hover-opacity !important;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  @include arrow-style($carousel-arrow-nav-width, $carousel-arrow-nav-height);
}

.carousel-control-prev-icon {
  @include arrow-prev-light();
}

.carousel-control-next-icon {
  @include arrow-next-light();
}

.carousel-nav-dark {

  .carousel-control-prev-icon {
    @include arrow-prev();
  }

  .carousel-control-next-icon {
    @include arrow-next();
  }
}

.carousel-nav-sm {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: $carousel-arrow-nav-width-sm;
  }
}

.carousel-nav-lg {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: $carousel-arrow-nav-width-lg;
  }
}

//
// Optional indicator pips
//

.carousel-indicators {
  bottom: 4px;
  align-items: flex-end;
  height: $carousel-indicator-height * 3;

  li {
    @include transition-prefix("all", $transition-duration-base, $transition-timing-base);
  }

  .active {
    height: $carousel-indicator-height * 3;
  }
}

// Carousel indicator circle
.carousel-indicator-circle {
  bottom: 9px;
  align-items: center;
  height: $carousel-indicator-height * 8;

  li {
    width: $carousel-indicator-circle-height;
    height: $carousel-indicator-circle-height;
    margin-right: $carousel-indicator-circle-spacer;
    margin-left: $carousel-indicator-circle-spacer;
    border-radius: $circle-border-radius;

    @include transition-prefix("all", 0s, $transition-timing-base);
  }

  .active {
    width: $carousel-indicator-circle-active;
    height: $carousel-indicator-circle-active;
    margin-right: $carousel-indicator-circle-spacer / 2;
    margin-left: $carousel-indicator-circle-spacer / 2;
    background-color: transparent;
    border: ($carousel-indicator-circle-height / 2) solid $white;
  }
}

//
// Optional captions
//

.carousel-caption {
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  padding: $carousel-caption-padding;

  @include gradient-y($gradient-bg-element-color-start, $gradient-bg-element-color-end);

  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }
}
